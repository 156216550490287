import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../common/global';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  link_id: any;
  projid: any;
  linkdetail: any;
  projdetail: any;
  error: any;
  getprojcatname: any;
  baseUrl: string;
  image: any;
  shareddata:any;

  constructor(private sanitizer:DomSanitizer,private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private httpClient: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");

    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }

    this.route.queryParams.subscribe(params => {
      this.link_id = params['link_id'];
      this.projid = params['id'];
      const userid = localStorage.getItem("UserID");
      this.SpinnerService.show();
      //Project
      this.httpClient.get<any>(this.baseUrl + "/getsingleprojectsdata.php?proj_id=" + this.projid + "&user_id=" + userid).subscribe((dataprojdet: any) => {
        this.projdetail = dataprojdet;
        if (dataprojdet.length > 0) {
          for (var i = 0; i < dataprojdet.length; i++) {
            var Categories = dataprojdet[i].Categories;
            var catArr = Categories.split(',');
            var newcatArr = [];
            for (var j = 0; j < catArr.length; j++) {
              newcatArr.push(
                '<span class="badge badge-primary" (click)="setActiveProject(' + dataprojdet[i].id + ',\'' + catArr[j] + '\')">' + catArr[j] + '</span>'
              );
            }
            dataprojdet[i].Categories = newcatArr;
          }
        }
      });
      //Link
      this.httpClient.get<any>(this.baseUrl + "/getsinglelinksdata.php?link_id=" + this.link_id + "&user_id=" + userid).subscribe((data: any) => {
        this.linkdetail = data;
        if (data.length > 0) {
          this.httpClient.get<any>(this.baseUrl + "/getsinglelinksdatawithusers.php?link_id=" + this.link_id).subscribe((data1: any) => {
            this.shareddata=data1;
          },
          error => {
          });
          for (var i = 0; i < data.length; i++) {
            //
            var sharedURL = data[i].pin_url.split(',');
            debugger;
            var sharedLinks = [];
            for (var k = 0; k < sharedURL.length; k++) {
              sharedLinks.push(
                '<a target="_blank" href="'+sharedURL[k]+'" >' + sharedURL[k] + '</a>'
              );
            }
            data[i].pin_url = sharedLinks;
            var catArr = data[i].Categories.split(',');
            var newcatArr = [];
            for (var j = 0; j < catArr.length; j++) {
              newcatArr.push(
                '<span class="badge badge-primary" (click)="setActiveProject(' + data[i].id + ',\'' + catArr[j] + '\')">' + catArr[j] + '</span>'
              );
            }
            data[i].Categories = newcatArr;
          }
        }
      });
      
    });
  }
  pinterest(title: string, projid: number) {
    const projtitle = title;
    const data = {}
    this.httpClient.post<any>(this.baseUrl + "/getprojectdetails.php?id=" + projid, data).subscribe((dataprojcat: any) => {
      this.getprojcatname = dataprojcat;
      this.image = dataprojcat[0].image;
      this.router.navigate(['/share-project'], { queryParams: { id: '' + projid + '' } });
      window.open("http://pinterest.com/pin/create/button/?url=" + projtitle + "&media=" + this.image + "&method=button", "_blank");
    },
      error => {
        if (error.status == 401)
          this.error = error.error[0].message;
      });

  }
  getpinURL(pinurl) {
    if (pinurl == '') return 'text-muted'
    else if (pinurl == null) return 'text-muted'
    else if (pinurl == undefined) return 'text-muted'
    else if (pinurl == pinurl) return 'text-muted disabled'
  }
  getpinIMG(pinimg) {
    if (pinimg == '') return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg == null) return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg == undefined) return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg != '') return 'img-thumbnail rounded mx-auto d-block'
  }
  getprojeimage(projimg) {
    if (projimg == '') return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg == null) return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg == undefined) return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg != '') return 'img-thumbnail rounded mx-auto d-block'
  }
  getYourProj(userid) {
    const userlocid = localStorage.getItem("UserID");
    if (userid == userlocid) return 'text-muted'
    if (userid != userlocid) return 'text-muted disabled'
  }

}
